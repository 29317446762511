import Vue from 'vue';
import App from './App.vue';
//6.引入导出的路由
import router from './router/index'
import { Button, Swipe, SwipeItem, Dialog, Field, NavBar, Tab, Tabs, Popup, Toast } from 'vant';
import videoPlayer from 'vue-video-player';

import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
// 转换时间格式
import moment from 'moment/moment'
Vue.filter('moment', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  return moment(value).format(formatString);

});
Vue.filter('moments', function (value, formatString) {
  formatString = formatString || 'MM-DD';
  return moment(value).format(formatString);

});
Vue.use(videoPlayer);



Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);

Vue.use(Popup);
Vue.use(Toast);
Vue.config.productionTip = false

new Vue({
  //7.注册路由
  router,
  render: h => h(App),
}).$mount('#app')
