<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {
    //本地
    // this.$router.replace("/");
    // //测试环境
    // if (this._isMobile()) {
    //   //移动端
    //   this.$router.replace("/");
    //   //window.location.href = "https://www.jdqny.com/jdmobile";
    // } else {
    //   //pc端
    //   window.location.href = "https://www.jdqny.com/jdwebsite";
    // }
    // 生产环境
    // 判断进入的是那个端 跳入相应的路由 页面
    if (this._isMobile()) {
      //移动端
      this.$router.replace("/");
      // window.location.href = "https://m.jiadivip.com/";
    } else {
      //pc端
      window.location.href = "https://jiadivip.com/";
    }
  },
  methods: {
    //判断 是否进入的是移动端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  background-color: #ffffff;
}
</style>
