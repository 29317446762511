//1.导入VueRouter
import Vue from "vue";
import VueRouter from "vue-router";
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  mode: "hash",
  //4.配置路由的path和组件
  routes: [
    {
      path: "/",
      redirect: "home",
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/home"),
    },
    // 新闻列表
    {
      path: "/newsList",
      name: "newsList",
      component: () => import("@/views/newsList"),
    },
    // 新闻详情
    {
      path: "/newsDetail",
      name: "newsDetail",
      component: () => import("@/views/newsDetail"),
    },
    // 新闻详情
    {
      path: "/newsOne",
      name: "newsOne",
      component: () => import("@/views/newsOne"),
    },
    // 新闻详情
    {
      path: "/newsTwo",
      name: "newsTwo",
      component: () => import("@/views/newsTwo"),
    },
    // 新闻详情
    {
      path: "/newsThree",
      name: "newsThree",
      component: () => import("@/views/newsThree"),
    },
    // 人才招聘
    {
      path: "/Recruitment",
      name: "Recruitment",
      component: () => import("@/views/Recruitment"),
    },
    // 品牌故事
    {
      path: "/brandStory",
      name: "brandStory",
      component: () => import("@/views/brandStory"),
    },
    // 联系我们contactUs
    {
      path: "/contactUs",
      name: "contactUs",
      component: () => import("@/views/contactUs"),
    },
    // 商务团队
    {
      path: "/bussinessTeam",
      name: "bussinessTeam",
      component: () => import("@/views/bussinessTeam"),
    },
    // 设计团队designTeam
    {
      path: "/designTeam",
      name: "designTeam",
      component: () => import("@/views/designTeam"),
    },
    // 设计师详情
    {
      path: "/designDetail",
      name: "designDetail",
      component: () => import("@/views/designDetail"),
    },
    // 精选案例selectedCase
    {
      path: "/selectedCase",
      name: "selectedCase",
      component: () => import("@/views/selectedCase"),
    },
    // 案例详情caseDetail
    {
      path: "/caseDetail",
      name: "caseDetail",
      component: () => import("@/views/caseDetail"),
    },
    //  服务流程serviceProcess
    {
      path: "/serviceProcess",
      name: "serviceProcess",
      component: () => import("@/views/serviceProcess"),
    },
  ],
});
//  挂载路由守卫
// to 将访问哪一个路径
// from 代表从哪个路径跳转而来
// next 是一个函数,表示放行

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  //next() 放行 next('/login') 强制跳转
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
  next();
});
//5.导入路由实例
export default router;
